import React from 'react';
import cn from 'classnames';
import { LogoProduct } from '@dealroadshow/uikit';
import useRouter from '@/Framework/hooks/useNextRouter';
import Favicon from './components/Favicon';
import Header from '@/ui/shared/components/Landing/Sections/Header';
import CookiesBanner from '@/ui/shared/components/Landing/CookiesBanner';
import ScrollBodyBackground from '@/ui/shared/components/Layout/ScrollBodyBackground';
import Footer from './components/Footer';
import TenantContext from '@/Framework/Tenant/TenantContext';
import config from '@/dataroom/application/config/config';
import stickyFooterStyles from '@/ui/shared/components/Landing/StickyFooter/stickyFooter.scss';
import styles from './tenantResearchRoomStyles.scss';
import BookDemoButton from '@/ui/shared/modules/ScheduleDemo/ScheduleDemoModal/BookDemoButton';

interface IProps {
  children: React.ReactNode,
}

const getHeaderMenu = (pathname) => [
  {
    key: 'features',
    title: 'Features',
    path: '/features',
  },
  {
    key: 'process',
    title: 'Process',
    path: '/process',
  },
  {
    key: 'regulations',
    title: 'Regulations',
    path: '/regulations',
    isActive: () => pathname.startsWith('/regulations'),
  },
  {
    key: 'pricing',
    title: 'Pricing',
    path: '/pricing',
    isActive: () => pathname.startsWith('/pricing'),
  },
];

const TenantResearchRoom = ({ children }: IProps) => {
  const tenant = config.tenant.tenantResearchRoom.code;
  const { asPath: pathname } = useRouter();

  return (
    <TenantContext tenant={ tenant }>
      <Favicon />
      <Header
        logoProduct={ LogoProduct.RESEARCHROOM }
        logoClassName={ styles.largeLogo }
        menu={ getHeaderMenu(pathname) }
        headerButton={ ({ buttonProps }) => (
          <BookDemoButton
            { ...buttonProps }
            className={ styles.bookDemoButton }
          />
        ) }
        mobileMenuClassName={ styles.mobileMenu }
      />
      <div className={ cn(styles.pageContainer, stickyFooterStyles.pageContainerWithStickyFooter) }>
        <ScrollBodyBackground tenant={ tenant } minHeight={ 1000 } />
        { children }
      </div>
      <Footer />
      <CookiesBanner />
    </TenantContext>
  );
};

export default TenantResearchRoom;
