import React from 'react';
import { footerConfig } from './footerConfig';
import SectionFooter from '@/ui/shared/components/Landing/Sections/Footer';
import styles from './footer.scss';

const Footer = () => (
  <SectionFooter
    config={ footerConfig() }
    contentClassName={ styles.Content }
  />
);

export default Footer;
